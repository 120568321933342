import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import AddBlog from "./AddBlog";
import Swal from "sweetalert2";
import { Editor } from "@tinymce/tinymce-react";
import withReactContent from "sweetalert2-react-content";
import AssetImageUpload from "./AssetImageUpload";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const MySwal = withReactContent(Swal);

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  shortTitle: Yup.string().required("Short Title is required"),
  metaTitle: Yup.string().required("Meta Title is required"),
  metaDescription: Yup.string().required("Meta Description is required"),
  metaTag: Yup.string().required("Meta Tag is required"),
  slug: Yup.string().required("Slug is required"),
});

function AddBlogForm() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [blogImage, setBlogImage] = useState(null);
  const [closeForm, setCloseForm] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiKey = process.env.REACT_APP_TINY_CLOUD_API;

  const [status, setStatus] = useState("");
  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleBannerImageChange = (e) => {
    setBannerImage(e.target.files[0]);
  };

  const handleBlogImageChange = (e) => {
    setBlogImage(e.target.files[0]);
  };

  const handleEditorChange = (editorState) => {
    setEditorContent(editorState);
  };

  const handleAssetImageUpload = (imageUrl) => {
    console.log("Received image URL:", imageUrl);
  };

  const showAssetImageUploadModal = () => {
    MySwal.fire({
      title: "Upload Asset Image",
      html: <AssetImageUpload onUpload={handleAssetImageUpload} />,
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
    });
  };

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  const handleInputChange = (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value.trimStart());
  };

  // Handle form submission with status
  const handleSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    const {
      title,
      shortTitle,
      metaTitle,
      metaDescription,
      metaTag,
      slug,
      status,
    } = values;

    if (!editorContent) {
      toast.error("Editor content is required");
      setIsSubmitting(false);
      return;
    }
    const modifiedEditorContent = editorContent.replace(
      /<p>(<img[^>]*>)<\/p>/g,
      "$1"
    );

    const formDataToSend = new FormData();
    formDataToSend.append("title", title);
    formDataToSend.append("shortTitle", shortTitle);
    formDataToSend.append("metaTitle", metaTitle);
    formDataToSend.append("metaDescription", metaDescription);
    formDataToSend.append("metaTags", metaTag);
    formDataToSend.append("slug", slug.toLowerCase());

    formDataToSend.append("editorContent", modifiedEditorContent);
    formDataToSend.append("status", status || "Active");

    if (blogImage) {
      formDataToSend.append("image", blogImage);
    }
    if (coverImage) {
      formDataToSend.append("coverImage", coverImage);
    }
    if (bannerImage) {
      formDataToSend.append("bannerImage", bannerImage);
    }

    try {
      const response = await axios.post("/blog", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        toast.success("Blog has been added successfully");
        setCloseForm(true);
        navigate("/blog-management");
        resetForm(); // Reset the form after successful submission
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to add blog. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (closeForm) {
    return <AddBlog />;
  }

  return (
    <>
      <ToastContainer />
      <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold mb-6">Add Blog Post</h2>
        <div className="flex justify-end">
          <button
            onClick={closeFormAndOpenPage}
            className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
            style={{
              backgroundImage:
                "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            <span className="ml-2">Back</span>
          </button>
        </div>

        <Formik
          initialValues={{
            title: "",
            shortTitle: "",
            metaTitle: "",
            metaDescription: "",
            metaTag: "",
            slug: "",
            status: "Active", // Default status
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, submitForm }) => (
            <Form encType="multipart/form-data">
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="shortTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Short Title
                </label>
                <Field
                  type="text"
                  id="shortTitle"
                  name="shortTitle"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="shortTitle"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="metaTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Title
                </label>
                <Field
                  type="text"
                  id="metaTitle"
                  name="metaTitle"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="metaTitle"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="metaDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Description
                </label>
                <Field
                  as="textarea"
                  id="metaDescription"
                  name="metaDescription"
                  rows="3"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="metaDescription"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="metaTag"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Tag
                </label>
                <Field
                  type="text"
                  id="metaTag"
                  name="metaTag"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="metaTag"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700"
                >
                  Slug
                </label>
                <Field
                  type="text"
                  id="slug"
                  name="slug"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="slug"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="blogImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Blog Image
                </label>
                <input
                  type="file"
                  id="blogImage"
                  name="blogImage"
                  accept="image/*"
                  onChange={handleBlogImageChange}
                  className="mt-1 block w-full"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="bannerImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Banner Image
                </label>
                <input
                  type="file"
                  id="bannerImage"
                  name="bannerImage"
                  accept="image/*"
                  onChange={handleBannerImageChange} // Handler for blog image
                  className="mt-1 block w-full"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="coverImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cover Image
                </label>
                <input
                  type="file"
                  id="coverImage"
                  name="coverImage"
                  accept="image/*"
                  onChange={handleCoverImageChange}
                  className="mt-1 block w-full"
                />
              </div>

              <div className="mb-4">
                <button
                  type="button"
                  className="bg-indigo-500 text-white px-4 py-2 rounded"
                  onClick={showAssetImageUploadModal}
                >
                  Upload Asset Image
                </button>
              </div>
              <div className="mb-4" style={{ position: "relative" }}>
                <Editor
                  apiKey={apiKey}
                  init={{
                    height: 600,
                    plugins: [
                      // Core editing features
                      "anchor",
                      "autolink",
                      "charmap",
                      "codesample",
                      "emoticons",
                      "image",
                      "link",
                      "lists",
                      "media",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "textcolor",

                      // Your account includes a free trial of TinyMCE premium features
                      // Try the most popular premium features until Nov 13, 2024:
                      // "checklist",
                      // "mediaembed",
                      // "casechange",
                      // "export",
                      // "formatpainter",
                      // "pageembed",
                      // "a11ychecker",
                      // "tinymcespellchecker",
                      // "permanentpen",
                      // "powerpaste",
                      // "advtable",
                      // "advcode",
                      // "editimage",
                      // "advtemplate",
                      // "ai",
                      // "mentions",
                      // "tinycomments",
                      // "tableofcontents",
                      // "footnotes",
                      // "mergetags",
                      // "autocorrect",
                      // "typography",
                      // "inlinecss",
                      // "markdown",
                      // Early access to document converters
                      // "importword",
                      // "exportword",
                      // "exportpdf",
                      "code",
                      "preview",
                      "pagebreak",
                    ],
                    toolbar:
                    "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | link anchor image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code preview pagebreak",
                    tinycomments_mode: "embedded",
                    tinycomments_author: "Author name",
                    mergetags_list: [
                      { value: "First.Name", title: "First Name" },
                      { value: "Email", title: "Email" },
                    ],
                    ai_request: (request, respondWith) =>
                      respondWith.string(() =>
                        Promise.reject("See docs to implement AI Assistant")
                      ),
                    exportpdf_converter_options: {
                      format: "Letter",
                      margin_top: "1in",
                      margin_right: "1in",
                      margin_bottom: "1in",
                      margin_left: "1in",
                    },
                    exportword_converter_options: {
                      document: { size: "Letter" },
                    },
                    importword_converter_options: {
                      formatting: {
                        styles: "inline",
                        resets: "inline",
                        defaults: "inline",
                      },
                    },
                    forced_root_block: false,
                  }}
                  onEditorChange={handleEditorChange} // Handle changes
                />
              </div>

              <br />

              <div className="flex justify-end">
                <button
                  type="button"
                  className="w-full inline-flex items-center justify-center px-4 py-2 mr-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700 focus:outline-none"
                  onClick={() => {
                    setFieldValue("status", "Inactive"); // Set status to draft
                    submitForm(); // Submit the form programmatically
                  }}
                  disabled={isSubmitting}
                >
                  Save as Draft
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white ${
                    isSubmitting
                      ? "bg-gray-500"
                      : "bg-blue-600 hover:bg-blue-700"
                  } focus:outline-none`}
                >
                  {isSubmitting ? "Submitting..." : "Publish"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddBlogForm;
